/*
 *  Inline
 */

.inline {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: var(--s2);
  margin-bottom: var(--s2);
}

.inline-photo {
  font-size: var(--text-sm);
}

.inline.inline-photo > * + * {
  margin-top: var(--s-1);
}

.inline-text {
  background-color: var(--grey);
  padding: var(--s0);
}

.inline-text > * + * {
  margin-top: var(--s0);
}

.inline-text h2 {
  font-weight: bold;
  font-size: var(--text-xl);
}

.inline_gallery {
  background-color: var(--grey);
  padding: var(--s0);
}

.inline_gallery > * + * {
  margin-top: var(--s0);
}

.inline_gallery .type {
  font-weight: bold;
}

.inline_gallery .name {
  font-weight: bold;
  font-size: var(--text-xl);
}

.inline_gallery .gallery {
  max-width: 180px;
}

.inline_photo {
  font-size: var(--text-sm);
}

.inline_photo .fr-inner {
  display: inline-block;
  margin-top: var(--s-1);
}
