/*
 *  Card component
 */

/*******************************************************************************
 *  Card
 *******************************************************************************/

/* 1. Allows the clickable card link to be stretched over the full area of the card */
/* 2. When wrapped, the thumbnail will go above the card text instead of below */
.card {
  position: relative; /* 1 */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse; /* 2 */
  gap: var(--s0);
}

.card__text {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 60%;
  display: flex;
  flex-direction: column;
  gap: var(--s0);
}

.card__title {
  font-size: var(--text-xl);
  font-weight: bold;
}
.card__title a {
  font-style: normal;
}

.card__link::after {
  /* stretch the clicking space over the area of the card */
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.card__byline {
  font-size: var(--text-sm);
}

.card__byline a {
  /* Add back the link underline */
  text-decoration: revert;

  /* Make the link clickable above the card link */
  position: relative;
  z-index: 1;
}

.card__thumbnail {
  flex-basis: 15rem;
  flex-grow: 1;
  width: 15rem;
}

/*******************************************************************************
 *  List
 *******************************************************************************/

.card-list {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--s0);
}

.card-list > * + * {
  border-top: 1px solid var(--grey);
  padding-top: var(--s0);
}
