/*
 *  Gallery
 */

.gallery__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--s0);
}

.gallery__thumbnail {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 9.5rem;
}



.gallery-card {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: var(--s-1);
}

.gallery-card__thumbnail {
  height: 15rem;
}

.gallery-card__title {
  font-size: var(--text-xl);
  font-weight: bold;
}

.gallery-card__title::after {
  /* stretch the clicking space over the area of the card */
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}



.gallery-card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: var(--s1) var(--s0);
}