/*******************************************************************************
 *  General Element Styles
 *******************************************************************************/

body {
  /* Set to flex so main can flex-grow so the footer will always be at the bottom */
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;

  /* Root styles */
  font-family: "Lato", sans-serif;
  color: var(--black);
}

a {
  color: inherit;
  text-underline-offset: 0.15em;
}

img {
  object-fit: contain;
}

/*******************************************************************************
 *  Accessibility
 *******************************************************************************/

:focus-visible {
  /* Styling for focus outline */
  outline: 0.25rem solid #0274b7;
  outline-offset: 0.25rem;
}

/* From https://make.wordpress.org/accessibility/handbook/markup/the-css-class-screen-reader-text/ */

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/*******************************************************************************
 *  Utilities
 *******************************************************************************/

.float-left {
  float: left;
  margin-right: var(--s0);
}

.hidden {
  display: none;
}
