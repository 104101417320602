/*
 *  Homepage
 */

/*******************************************************************************
 *  Featured
 *******************************************************************************/

.homepage__featured {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--s0);

  border-bottom: 1px solid var(--grey);
  padding-bottom: var(--s1);
}

.homepage__featured__top-story img.card__thumbnail {
  display: initial;
}

.homepage__featured__top-story div.card__thumbnail {
  display: none;
}

.homepage__featured__list {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--s0);
}

@media (max-width: 65rem) {
  .homepage__featured__list > * {
    border-top: 1px solid var(--grey);
    padding-top: var(--s0);
  }
}

@media (min-width: 65rem) {
  .homepage__featured {
    flex-flow: row nowrap;
  }

  .homepage__featured__top-story {
    flex-basis: 30rem;
    flex-grow: 1;

    flex-flow: column nowrap;
  }

  .homepage__featured__top-story img.card__thumbnail {
    display: none;
  }

  .homepage__featured__top-story div.card__thumbnail {
    display: initial;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .homepage__featured__top-story .card__text {
    flex-grow: 0;
  }

  .homepage__featured__top-story .card__title {
    font-size: 1.875rem;
  }

  .homepage__featured__list {
    flex-basis: 25rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--s0);
  }

  .homepage__featured__list .card {
    flex-flow: column nowrap;
    gap: var(--s-2);
  }

  .homepage__featured__list .card__thumbnail {
    flex-basis: 8rem;
    flex-grow: 0;
    height: 8rem;
  }

  .homepage__featured__list .card__text {
    gap: var(--s-2);
  }

  .homepage__featured__list .card__title {
    font-size: var(--text-lg);
  }
}



/*******************************************************************************
 *  Body
 *******************************************************************************/

.homepage__section [data-layout="columns"] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: var(--s0);
}

@media (max-width: 45rem) {
  .homepage__section[data-layout="columns"] {
    grid-template-columns: 1fr;
  }
}

.homepage__section:not([data-layout="columns"]) > * + * {
  margin-top: var(--s0);
}



/*******************************************************************************
 *  Misc
 *******************************************************************************/

#chartbeat-popular-stories h2 {
  font-size: var(--text-xl);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: var(--s0);
}

#chartbeat-popular-stories li + li {
  margin-top: var(--s0);
}