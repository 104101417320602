/*
 *  Article
 *
 *  URL: /news/<year>/<month>/<day>/<slug>/
 *  Template: news/story_detail
 */

.article img {
  width: 100%;
}

.article > * + * {
  margin-top: var(--s2);
}

.article__lead-photo {
  font-size: var(--text-sm);
}

.article__lead-photo > * + * {
  margin-top: var(--s-1);
}

.article__byline a {
  text-decoration: underline;
}

/*.article__lead-photo {
  gap: var(--s-1);
}

.article__lead-photo figcaption {
  font-size: 0.9rem;
  color: #777777
}*/

.article__body {
  font-family: "PT Serif", serif;
  font-size: var(--text-xl);
  line-height: 1.65em;
}

/*.article__body > * + *, .article__body > story > * + * {
  margin-top: var(--s0);
}*/

.article__body * + p {
  margin-top: var(--s0);
}

.article__body a {
  text-decoration: underline;
  text-underline-offset: 0.15em;
}

.article__body ol,
.article__body ul {
  list-style: initial;
  padding-left: 1.2em;
}

.article__body blockquote {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: var(--text-xl);
}

/*.article__inline, .inline__block {
  padding: var(--s1);
  font-family: "Lato", sans-serif;
  font-size: initial;
  line-height: initial;
  background-color: var(--light-grey);
}

* + .article__inline,
.article__inline + * {
  margin-top: var(--s2);
}*/
