/*
 *  Subheader
 */

.subheader__content {
  display: flex;
  align-items: stretch;
  gap: var(--s0);
}

/*******************************************************************************
 *  Main Links
 *******************************************************************************/

.subheader__links {
  display: flex;
  gap: var(--s0);
  flex-grow: 1;

  /* Fade out the overflow */
  overflow-x: auto;
  mask-image: linear-gradient(90deg, rgb(255, 255, 255) 95%, rgba(255, 255, 255, 0) 100%);
  -webkit-mask-image: linear-gradient(to right, var(--white) calc(100% - var(--s2)), var(--transparent) 100%);
  padding-right: var(--s2);

  scrollbar-width: none; /* Hide the scrollbar in Firefox */

  /* Add negative margin so the focus outline on the first link doesn't get clipped. */
  /* The fancy equation just makes it go all the way to the side of the screen. */
  margin-left: calc(-1 * max(var(--s0), calc((100vw - 75rem) / 2)));
}

.subheader__links::-webkit-scrollbar {
  /* Hide the scrollbar on webkit browsers */
  width: 0;
  height: 0;
}

.subheader__links li {
  display: flex;
  align-items: center;

  margin-top: var(--s0);
  margin-bottom: var(--s0);
}

.subheader__links li:first-child {
  /* Make up for the negative margin in subheader__links */
  margin-left: max(var(--s0), calc((100vw - 75rem) / 2));
}

.subheader__links a {
  white-space: nowrap;
}

.subheader__links li:first-child a {
  /* This is (we assume) the e-edition link */
  display: inline-flex;
  align-items: center;
  gap: var(--s-3);
}

/*******************************************************************************
 *  Social Links
 *******************************************************************************/

.subheader__social-links {
  display: flex;
  gap: var(--s0);
}

.subheader__social-links li {
  display: flex;
  align-items: center;
}

.subheader__social-links a {
  display: flex;
  align-items: center;
}
