/*******************************************************************************
 *  Buttons
 *******************************************************************************/

.button {
  /* Flex */
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  gap: var(--s-3);

  /* Box sizing */
  border: 1px solid var(--black);
  border-radius: 1px;
  padding: var(--s-3) var(--s-2);

  font-size: var(--text-lg);
  text-decoration: none;
  cursor: pointer;
}

.button--primary {
  background-color: var(--black);
  color: var(--white);
}

/*******************************************************************************
 *  Icons
 *******************************************************************************/

.icon {
  /* Works best with icons from https://heroicons.com/ */
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  /*vertical-align: -.22em; */
}

/*******************************************************************************
 *  Content Box
 *******************************************************************************/

.content-box {
  background-color: var(--grey);
  padding: var(--s0);
}

.content-box > * + * {
  margin-top: var(--s0);
}

.content-box a:not(.button) {
  text-decoration: revert;
}

.content-box li {
  line-height: 1.7em;
}

.content-box__title {
  font-weight: bold;
  font-size: var(--text-xl);
}
